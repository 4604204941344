/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"PEV Ústí nad Labem - Kontakt"}>
        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"n02fz00obj"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Oficiální zastoupení společnosti Topimplynem s.r.o. pro Ústí nad Labem"}>
              </Subtitle>

              <Text className="text-box fs--18" content={"&nbsp;IČO  : 21012946\n<br><span style=\"font-weight: bold;\">Tel. :  +420 608 619 791\n</span><br><span style=\"font-weight: bold;\">Email: iveta.tesarzova@topimplynem.cz<br>Adresa : Raisova 2, Střekov 400 03, Ústí nad Labem<br></span>"}>
              </Text>

              <Text className="text-box fs--8" content={"<span style=\"color: rgba(72, 81, 123, 0);\">Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis\nInstalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost kominík kominík kominík kominík kominík kominík kominík kominictví kominictví kominictví kominictví kominictví kominictví kominictvíZadejte text</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--18" content={"<span style=\"font-weight: bold;\">Chete nezávaznou cenovou nabídku, případně více informací? Kontaktujte nás!</span>"}>
              </Subtitle>

              <ContactForm className="--shape2 --style1 --shadow4 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="css-17zetwm pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Hledáte spolehlivého instalatéra?</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}